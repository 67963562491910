<template>
  <div class="container-xxl gx-5">
    <SingularTaskLayout
      :title="$t('document.documentRecording.title')"
      :task="currentTask || {}"
      :is-loading-task="tasksLoading"
      :validate-task-before-complete-hook="submitTask"
      :is-actions-disabled="!currentDoc"
      :should-disable-ignore-warnings="shouldDisableIgnoreWarnings"
      @complete-task="() => {}"
      @skip-task="onSkipTask"
    >
      <template slot="pagination">
        <el-pagination
          small
          background
          layout="prev, pager, next, jumper"
          class="d-flex align-items-center"
          :class="$direction === 'rtl' ? 'text-left' : 'text-right'"
          :page-count="unrecordedTasksCount"
          :current-page="taskNumber"
          @current-change="onUpdateTaskNumber"
        />
      </template>
      <template slot="menu-dropdown-items">
        <el-dropdown-item>
          <div @click="toggleDeclarationRequired">
            <ReturnIcon width="24px" height="24px" color="black" />
            <span class="mx-1">
              {{
                documentationRequired
                  ? $t('document.documentRecording.removeDocumentationRequired')
                  : $t('document.documentRecording.documentationRequired')
              }}
            </span>
          </div>
        </el-dropdown-item>
        <el-dropdown-item>
          <div class="text-danger" @click="removeDocument">
            <TrashCanIcon width="24px" height="24px" />
            <span class="mx-1">
              {{ $t('document.documentsOverview.classificationTasks.menuOptions.removeDocument') }}
            </span>
          </div>
        </el-dropdown-item>
      </template>
      <template #content>
        <div class="d-flex justify-content-between mb-4">
          <div>
            <span v-if="documentationRequired" class="review-required-badge text-primary badge p-2">{{
              $t('document.documentRecording.documentationRequired')
            }}</span>
          </div>
        </div>

        <div v-if="documentErrors && documentErrors.length" class="card error-card p-4 mb-5">
          <div class="d-flex align-items-center gap-2">
            <NoticeIcon class="text-danger" width="18" height="18" />
            <p class="fw-bold">{{ $t('document.documentRecording.validation.errorBox.title') }}</p>
          </div>
          <ol>
            <li v-for="(error, i) in documentErrors" :key="i">
              <p v-if="error.fields.length">
                {{
                  $t('document.documentRecording.validation.errorBox.unbalanced', {
                    fields: error.fields
                      .slice(0, -1)
                      .map((field) => $t(`document.exports.schema.fields.${getFieldTranslationKey(field)}`))
                      .join(', '),
                    lastField: $t(
                      `document.exports.schema.fields.${getFieldTranslationKey(error.fields.slice(-1)[0])}`
                    ),
                  })
                }}
              </p>
            </li>
          </ol>
        </div>

        <div class="row form-size-limit">
          <div class="col-md-7 h-100">
            <template v-if="currentDoc">
              <document-form
                :key="currentDoc.id"
                ref="docForm"
                :doc="recordedDoc"
                :disable-actions="managerReviewRequired"
                @change="saveDocument"
              />
            </template>
          </div>

          <div class="col-md-5 h-100 overflow-auto">
            <document-ocr-manager
              v-if="currentDoc"
              :doc="currentDoc"
              :doc-template-data="docTemplateData"
              @textExtraction="onTextExtraction"
              @dataExtraction="updateForm"
              @documentRecognition="updateForm"
            />
          </div>
        </div>

        <CreateRescanTaskModal
          v-if="isCreateRescanTaskModalOpen"
          @close="isCreateRescanTaskModalOpen = false"
          @createRescanTask="handleCreateRescanTask"
        />
        <ValidationWarningsModal
          v-if="validationWarnings.length"
          class="modalIndex"
          :warnings="validationWarnings"
          @close="handleCloseWarningsModal"
          @warnings-debunked="handleClearWarnings"
        />
        <TaskCommentModal
          v-if="commentReviewModalOpen"
          :comment="currentTask && currentTask.data && currentTask.data.comment"
          @commentSubmitted="handleCommentSubmitted"
          @close="commentReviewModalOpen = !commentReviewModalOpen"
        />
      </template>
    </SingularTaskLayout>
  </div>
</template>

<script type="text/javascript">
import { isNil, isEmpty, reject, omit, pick, clone } from 'ramda';
import { ref, computed, getCurrentInstance } from 'vue';

import { useDocumentTasks } from '@/modules/document/compositions';
import { ParamsSchema, ItemsParamsSchema, ReferencesParamsSchema } from '@/imports/api/schemas/documents';

import { NoticeIcon, TrashCanIcon, ReturnIcon } from '@/assets/icons';
import { DOCUMENT_QUERY_NEW2, convertDocument } from '@/modules/document/compositions/documents-queries';
import { recordDocument, deleteDocument, updateDocument } from '@/modules/document/compositions/documents-operations';
import {
  DocumentForm,
  DocumentOcrManager,
  CreateRescanTaskModal,
  ValidationWarningsModal,
  TaskCommentModal,
} from '@/modules/document/components';
import { useCreateTaskNew2, useCompleteTaskNew2, usePatchTaskNew2 } from '@/modules/tasks';
import { useTenancy } from '@/modules/auth';
import { getDefaultParams, BaseDocumentSchema } from '@/imports/api/schemas/documents';
import { SingularTaskLayout } from '../tasks/task/components';
import { DOCUMENT_FLAGS } from './types';
import DocumentSchemas from '@/imports/api/schemas/documents';
import { useRoute } from 'vue-router/composables';
// Analytics
import { ANALYTICS_ENUMS } from './utils/analytics';
import { ANALYTICS_EVENT_TYPES } from '@/analytics/types';
import { DOC_ANALYTICS_EVENT_TYPES } from '@/analytics/types/doc-analytics-types';
import { useAnalytics } from '@/analytics/compositions/useAnalytics';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const getUnconfiguredStructureParams = (structureParams, paramsSchema) =>
  paramsSchema.filter((key) => !structureParams[key]);

export default {
  meteor: {},
  components: {
    DocumentForm,
    DocumentOcrManager,
    NoticeIcon,
    CreateRescanTaskModal,
    ValidationWarningsModal,
    TaskCommentModal,
    SingularTaskLayout,
    ReturnIcon,
    TrashCanIcon,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { currentTenant } = useTenancy();
    const { logEvent } = useAnalytics();

    const {
      documentTasks: recordTasks,
      loading: tasksLoading,
      refetch: refetchTasks,
    } = useDocumentTasks(() => ({ businessId: currentTenant.value.id, type: 'record' }));

    const { createTask, loading: createTaskLoading } = useCreateTaskNew2();
    const { completeTask, loading: completeTaskLoading } = useCompleteTaskNew2();
    const { patchTask } = usePatchTaskNew2();

    const taskPage = Number(root.$route.query.taskNumber);
    const taskNumber = ref(Number.isNaN(taskPage) ? 1 : taskPage);
    const currentTask = computed(() => recordTasks.value[taskNumber.value - 1]);
    const shouldDisableIgnoreWarnings = ref(true);
    const route = useRoute();

    return {
      logEvent,
      currentTenant,
      createTask,
      createTaskLoading,
      recordTasks,
      tasksLoading,
      completeTask,
      completeTaskLoading,
      patchTask,
      refetchTasks,
      validationWarnings: ref([]),
      validationErrors: ref([]),
      taskNumber,
      currentTask,
      openSnoozeModal: ref(false),
      shouldDisableIgnoreWarnings,
      route,
    };
  },
  data() {
    return {
      showReviewEditButton: false,
      commentReviewModalOpen: false,
      currentDoc: null,
      recordedDoc: null,
      copyMode: false,
      isCreateRescanTaskModalOpen: false,
    };
  },
  computed: {
    schema() {
      const type = this.currentDoc?.type;
      return type ? DocumentSchemas[type] : BaseDocumentSchema;
    },
    unrecordedTasksCount() {
      return this.recordTasks.length;
    },
    managerReviewRequired() {
      return this.currentTask?.reviewRequired;
    },
    documentationRequired() {
      return this.currentDoc?.hasDeclaration ?? false;
    },
    dataReady() {
      return (
        Object.keys(this.$subReady).reduce((ready, key) => this.$subReady[key] && ready, true) &&
        !this.tasksLoading &&
        !this.completeTaskLoading &&
        !this.createTaskLoading
      );
    },
    docTemplateData() {
      const { supplierId, type: documentType } = this.currentDoc || {};
      return { supplierId, documentType };
    },
    documentErrors() {
      return this.validationErrors.find(({ path }) => !path.length)?.context?.invalidRelations;
    },
  },
  watch: {
    taskNumber: {
      handler(page) {
        this.$router.replace({ query: { taskNumber: page } });
      },
      immediate: true,
    },
    'currentDoc.id': {
      handler(value) {
        if (value)
          this.recordedDoc = {
            ...this.currentDoc,
            structureParams: this.currentDoc.structureParams || getDefaultParams(this.currentDoc.type),
          };
        this.validationWarnings = [];
        this.validationErrors = [];
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    async onSkipTask(taskId) {
      const loading = this.$loading();
      try {
        await this.refetchTasks();
        if (this.currentTask?.id === taskId) {
          this.onUpdateTaskNumber(this.taskNumber + 1);
        }
      } catch (error) {
        this.$message.error(`${error}`);
      } finally {
        loading.close();
      }
    },
    onUpdateTaskNumber(value) {
      this.currentDoc = null;
      if (value <= this.unrecordedTasksCount) {
        this.$router.replace({ query: { taskNumber: value } });
        this.taskNumber = value;
      } else if (this.unrecordedTasksCount && !(value <= this.unrecordedTasksCount)) {
        this.$router.replace({ query: { taskNumber: 1 } });
        this.taskNumber = 1;
      }
    },

    getFieldTranslationKey(field) {
      switch (field) {
        case 'items.totalAmount':
          return 'itemsTotalAmount';
        case 'generalCharges.amount':
          return 'generalChargesAmount';
        default:
          return field;
      }
    },
    async changeManagerReviewStatus(comment) {
      try {
        const patchParams = comment
          ? {
              data: { ...this.currentTask.data, comment },
              reviewRequired: true,
            }
          : { reviewRequired: !this.managerReviewRequired };

        await this.patchTask({
          taskId: this.currentTask.id,
          patchParams,
        });
      } catch (err) {
        console.error(err);
        this.$message.error(`${err.error} - ${err.reason}`);
      }
    },
    async changeDocumentationRequiredStatus() {
      try {
        await this.patchTask({
          taskId: this.currentTask.id,
          patchParams: { data: { ...this.currentTask.data, documentationRequired: !this.documentationRequired } },
        });
      } catch (err) {
        console.error(err);
        this.$message.error(`${err.error} - ${err.reason}`);
      }
    },
    async handleCreateRescanTask(rescanReason) {
      const currentFormData = this.$refs.docForm.model;
      const currentDocumentId = this.currentDoc.id;
      const taskCreateVariables = {
        businessId: this.currentTenant.id,
        domain: 'document',
        type: 'missingDocuments',
        data: reject(isNil)({
          id: currentDocumentId,
          documentNumber: currentFormData.documentNumber,
          type: currentFormData.type && !isEmpty(currentFormData.type) ? [currentFormData.type] : null,
          issueDate: currentFormData.issueDate,
          supplierId: currentFormData.supplierId,
          businessId: currentFormData.businessId,
          reason: rescanReason,
        }),
      };

      try {
        await this.createTask(taskCreateVariables);
      } catch (e) {
        this.$message.error(this.$t('document.documentRecording.rescanTask.createRescanTaskError'));
        return;
      }

      try {
        await this.completeTask({ taskId: this.currentTask.id });
      } catch (e) {
        this.$message.error(this.$t('document.documentRecording.rescanTask.completeRecordTaskError'));
        return;
      }

      this.$alert(
        this.$t('document.documentRecording.rescanTask.taskCreateConfirmationModal.content'),
        this.$t('document.documentRecording.rescanTask.taskCreateConfirmationModal.title'),
        {
          type: 'success',
          confirmButtonText: this.$t(
            'document.documentRecording.rescanTask.taskCreateConfirmationModal.continueToNextTask'
          ),
        }
      ).catch(() => null);

      this.currentDoc = null;

      await sleep(2000);
      await this.refetchTasks();
    },
    handleDocumentCantBeRecorded() {
      const currentFormData = this.$refs.docForm.model;

      if (!currentFormData.supplierId) {
        this.$alert(
          this.$t('document.documentRecording.rescanTask.validation.supplier'),
          this.$t('document.documentRecording.rescanTask.validation.cantCreateNewTask'),
          {
            type: 'error',
          }
        );
        return;
      }

      if (currentFormData.documentNumber || (currentFormData.type && currentFormData.issueDate)) {
        this.isCreateRescanTaskModalOpen = true;
        return;
      }

      this.$confirm(
        this.$t('document.documentRecording.rescanTask.validation.docParams'),
        this.$t('document.documentRecording.rescanTask.validation.cantCreateNewTask'),
        {
          type: 'warning',
        }
      )
        .then(() => {
          this.isCreateRescanTaskModalOpen = true;
        })
        .catch(() => null);
    },
    handleChangeReviewStatus() {
      if (!this.managerReviewRequired) {
        this.commentReviewModalOpen = true;
      } else {
        return this.changeManagerReviewStatus();
      }
    },
    async toggleDeclarationRequired() {
      const currentFormData = this.schema.clean(this.$refs.docForm.model);
      const currentDocumentFlags = pick(Object.values(DOCUMENT_FLAGS), this.currentDoc);
      const toggledValue = !currentDocumentFlags[DOCUMENT_FLAGS.HAS_DECLARATION];
      currentDocumentFlags[DOCUMENT_FLAGS.HAS_DECLARATION] = toggledValue;
      this.recordedDoc.hasDeclaration = toggledValue;
      await this.saveDocument({ ...currentFormData, ...currentDocumentFlags });
      this.currentDoc[DOCUMENT_FLAGS.HAS_DECLARATION] = toggledValue;
    },

    handleCommentSubmitted(comment) {
      return this.changeManagerReviewStatus(comment);
    },

    onKeydown(e) {
      if (e.key === 'c' && e.metaKey) this.copyMode = true;
    },
    onTextExtraction(texts) {
      if (this.copyMode) {
        navigator.clipboard.writeText(texts.join('\n'));
        this.$message('Copied to clipboard');
        this.copyMode = false;
      } else {
        this.$refs.docForm.insertDataToLastFocusedField(texts);
      }
    },
    updateForm(data) {
      this.shouldDisableIgnoreWarnings = true;
      this.$refs.docForm.updateForm(data);
    },

    moveToNextTask() {
      const nextTaskNumber = this.unrecordedTasksCount < this.taskNumber ? 1 : this.taskNumber;
      this.onUpdateTaskNumber(nextTaskNumber);
    },

    async removeDocument() {
      const confirmationResult = await this.$confirm(
        this.$i18n.t('document.documentsOverview.recordingTasks.menuOptions.removeDialog.message'),
        this.$i18n.t('document.documentsOverview.recordingTasks.menuOptions.removeDialog.title'),
        {
          confirmButtonText: this.$i18n.t('document.documentsOverview.recordingTasks.menuOptions.removeDialog.confirm'),
          cancelButtonText: this.$i18n.t('document.documentsOverview.recordingTasks.menuOptions.removeDialog.cancel'),
          type: 'warning',
        }
      ).catch(() => {
        return 'canceled';
      });

      if (confirmationResult !== 'confirm') return;
      const loading = this.$loading();
      try {
        await deleteDocument({ id: this.currentDoc.id });
        await sleep(2000);
        await this.refetchTasks();

        this.moveToNextTask();

        this.$message.success(this.$i18n.tc('document.documentsOverview.recordingTasks.message.removed'));
      } catch (err) {
        this.$message.error(`${err.error} - ${err.reason}`);
      } finally {
        loading.close();
      }
    },

    async saveDocument(formData) {
      this.shouldDisableIgnoreWarnings = true;

      this.currentDoc.replicateOf = formData.replicateOf;
      if (formData.paymentMethod) {
        if (isNil(formData.paymentMethod.type)) formData.paymentMethod = undefined;
        else
          formData.paymentMethod = {
            type: formData.paymentMethod.type,
            [formData.paymentMethod.type]: formData.paymentMethod[formData.paymentMethod.type],
          };
      }
      try {
        await updateDocument({
          id: this.currentDoc.id,
          data: this.convertISODates(formData),
        });
      } catch (err) {
        if (err.graphQLErrors?.length) {
          const { body } = err.graphQLErrors[0].extensions.response;
          this.$notify.error({ title: 'Save failed', message: body.message });
          console.error('save failed', body);
        } else {
          this.$notify.error({ title: 'Save failed', message: err.toString() });
          console.error('save failed', err);
        }
      }
    },

    convertISODates(document) {
      const clonedDocument = clone(reject(isNil)(document));
      const isoDatesFields = [
        'issueDate',
        'deliveryDate',
        'paymentDate',
        'paymentDueDate',
        'referencesFromDate',
        'referencesToDate',
      ];
      for (const isoDateField of isoDatesFields) {
        if (!isNil(clonedDocument[isoDateField]))
          clonedDocument[isoDateField] = clonedDocument[isoDateField].toISOString().split('T')[0];
      }

      if (!isNil(clonedDocument.references) && Array.isArray(clonedDocument.references))
        clonedDocument.references = clonedDocument.references.map((reference) => this.convertISODates(reference));

      return clonedDocument;
    },

    async submitTask() {
      const loading = this.$loading();
      this.shouldDisableIgnoreWarnings = false;

      const taskId = this.currentTask.id;
      await this.$nextTick();

      const result = await this.$refs.docForm.validateAndGetCleanedModel();

      if (isNil(result)) {
        loading.close();
        throw new Error('form validation invalid');
      }

      const { formData, setErrors: setErrorsCallback } = result;

      try {
        if (isNil(formData)) {
          loading.close();
          throw new Error('form validation invalid');
        }

        const currentDocId = this.currentDoc.id;
        const clearedDocumentData = this.clearFieldsNotConfiguredInStructureParams(formData);

        if (currentDocId !== this.currentDoc.id) {
          return { errors: [], warnings: [], shouldAbortCompletion: true };
        }

        const currentDocumentFlags = pick(Object.values(DOCUMENT_FLAGS), this.currentDoc);
        await updateDocument({
          id: this.currentDoc.id,
          data: this.convertISODates({
            ...clearedDocumentData,
            ...currentDocumentFlags,
          }),
        });

        await recordDocument({ id: this.currentDoc.id, ignoreWarnings: false });
        this.logEvent(ANALYTICS_EVENT_TYPES.TASKS.COMPLETED, {
          task_id: taskId,
          source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
          source_url: this.route?.fullPath,
          page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
          outcome_type: ANALYTICS_ENUMS.OUTCOME_TYPE.SUCCESS,
          outcome_details: null,
        });

        await sleep(2500);
        await this.refetchTasks();
        this.$message.success('Document recorded');
        this.moveToNextTask();

        return { errors: [], warnings: [], shouldAbortCompletion: true }; // shouldAbortCompletion is true because when document get recorded the task will be completed by document-workflow and we dont want it to be completed by taskLayout
      } catch (error) {
        const errors = [];
        if (error.graphQLErrors) {
          const { body } = error.graphQLErrors[0].extensions.response;
          if (body.details) {
            const validationErrors = body.details.filter((error) => !error.isWarning);
            const warningsResults = body.details.filter((error) => error.isWarning);
            if (!validationErrors.length && warningsResults.length) {
              this.validationWarnings = warningsResults;
              this.logEvent(ANALYTICS_EVENT_TYPES.TASKS.VALIDATION_WARNINGS, {
                task_id: taskId,
                source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
                source_url: this.route?.fullPath,
                page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
                outcome_type: ANALYTICS_ENUMS.OUTCOME_TYPE.WARNING,
                outcome_details: JSON.stringify(warningsResults),
              });
            }

            if (validationErrors.length) {
              this.validationErrors = validationErrors;
              setErrorsCallback(validationErrors);
              this.$message.error(`${body.message} - ${validationErrors[0].path.join('.')}`);
              errors.push(this.$t('document.documentRecording.generalRecordingError'));
              this.logEvent(ANALYTICS_EVENT_TYPES.TASKS.VALIDATION_ERRORS, {
                task_id: taskId,
                source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
                source_url: this.route?.fullPath,
                page_section: ANALYTICS_ENUMS.PAGE_SECTION.HEADER,
                outcome_type: ANALYTICS_ENUMS.OUTCOME_TYPE.ERROR,
                outcome_details: JSON.stringify(validationErrors),
              });
            }
          } else {
            console.error('recording failed A:', body);
            throw new Error(body);
          }
          console.error('recording failed B:', body);
        } else {
          console.error('recording failed C:', error);
          throw new Error(error.toString());
        }

        return { errors, warnings: [], shouldAbortCompletion: true };
      } finally {
        loading.close();
      }
    },

    async handleClearWarnings(warningsAnswers) {
      const loading = this.$loading();
      this.validationWarnings = [];
      try {
        const analyticTaskId = this.currentTask.id;
        const analyticCurrentUrl = this.route?.fullPath;

        await recordDocument({ id: this.currentDoc.id, ignoreWarnings: true });
        this.logEvent(DOC_ANALYTICS_EVENT_TYPES.MODAL_INTERACTION, {
          task_id: analyticTaskId,
          source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
          source_url: analyticCurrentUrl,
          modal_type: ANALYTICS_ENUMS.MODAL_TYPE.WARNING,
          modal_description: JSON.stringify(warningsAnswers ?? {}),
          trigger_action: ANALYTICS_ENUMS.TRIGGER_ACTION.COMPLETE_BUTTON_CLICKED,
          user_action_result: ANALYTICS_ENUMS.USER_ACTION_RESULT.SAVE,
          modal_interaction_value: JSON.stringify(warningsAnswers ?? {}),
        });

        await sleep(2000);
        await this.refetchTasks();
        this.$message.success('Document recorded');
        this.moveToNextTask();
      } catch (err) {
        this.$message.error(err.toString());
        console.error('recording failed', err);
      } finally {
        loading.close();
      }
    },
    clearFieldsNotConfiguredInStructureParams(documentData) {
      const { structureParams } = documentData;

      const unconfiguredDocumentParams = getUnconfiguredStructureParams(structureParams, ParamsSchema.objectKeys());
      const unconfiguredItemsParams = structureParams.items
        ? getUnconfiguredStructureParams(structureParams.items, ItemsParamsSchema.objectKeys())
        : [];
      const unconfiguredReferencesParams = structureParams.references
        ? getUnconfiguredStructureParams(structureParams.references, ReferencesParamsSchema.objectKeys())
        : [];
      const unconfiguredReferenceItemsParams = structureParams.references?.items
        ? getUnconfiguredStructureParams(structureParams.references.items, ItemsParamsSchema.objectKeys())
        : [];

      return omit(unconfiguredDocumentParams, {
        ...documentData,
        items: documentData.items?.map(omit(unconfiguredItemsParams)),
        references: documentData.references?.map((reference) =>
          omit(unconfiguredReferencesParams, {
            ...reference,
            items: reference.items?.map(omit(unconfiguredReferenceItemsParams)),
          })
        ),
      });
    },
    handleCloseWarningsModal({ trigger, warningsAnswers }) {
      this.validationWarnings = [];
      this.logEvent(DOC_ANALYTICS_EVENT_TYPES.MODAL_INTERACTION, {
        task_id: this.currentTask.id,
        source_page: ANALYTICS_ENUMS.SOURCE_PAGE.RECORD,
        source_url: this.route?.fullPath,
        modal_type: ANALYTICS_ENUMS.MODAL_TYPE.WARNING,
        modal_description: JSON.stringify(warningsAnswers ?? {}),
        trigger_action: ANALYTICS_ENUMS.TRIGGER_ACTION.COMPLETE_BUTTON_CLICKED,
        user_action_result:
          trigger === 'PRESSED_X'
            ? ANALYTICS_ENUMS.USER_ACTION_RESULT.CLOSE
            : ANALYTICS_ENUMS.USER_ACTION_RESULT.CANCEL,
        modal_interaction_value: JSON.stringify(warningsAnswers ?? {}),
      });
    },
  },
  apollo: {
    currentDoc: {
      query: DOCUMENT_QUERY_NEW2,
      update: (data) => convertDocument(data.documentNew2),
      variables() {
        return { id: this.currentTask.data.documentId };
      },
      skip() {
        return !this.currentTask;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/stylesheets/scss/global';

.review-required-badge {
  background: #f5f7fd;
}

.review-required-comment-alert {
  display: flex;
  flex-direction: row;
  background-color: #f9f2e8;
  padding: 16px;
  border-radius: 4px;
  height: 52px;
  color: #b85c00;
  font-size: small;
}

.review-required-comment-alert > div {
  margin-right: 10px;
}

.form-size-limit {
  height: calc(100vh - 148px);
  overflow: hidden;
}

.error-card {
  border-color: $error;
  background: change-color($color: $error, $lightness: 98%);
  color: $typography-primary;
}

.drop-down-btn {
  background-color: #f2f3f5;
  width: 30px;
  height: 30px;
}

.container-xxl {
  width: 100%;
  padding: 0 1.5rem;
  max-width: unset;
  margin: 0 auto;
}
</style>
